.common-flex-alc-jcs {
  display: flex;
  align-items: center;
  justify-content: center;
}
.common-flex-column {
  display: flex;
  flex-direction: column;
}
.common-flex-column-aic {
  display: flex;
  flex-direction: column;
  align-items: center;
}
