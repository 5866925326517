.card-container {
  width: 250px;
  height: auto;
  border: 1px solid #5229c0;
  border-radius: 6px;
  padding: 2rem;
  background: linear-gradient(90deg, #3f27b8 0, #d73afb 50%, #ffb546);
}
.login-title {
  font-size: 20px;
  margin-bottom: 1rem;
  color: #fff;
}
.login-container {
  display: flex;
  flex-direction: column;
  padding-left: 10%;
}
.login-input {
  margin-bottom: 1rem;
}
.login-icon {
  color: #fff;
  margin-right: 0.3rem;
}
.submit-btn {
  width: 100px;
  margin-left: 20%;
  background-color: rgba(28, 28, 28, 0);
  color: #fff;
  border: 1px solid #fff;
  border-radius: 6px;
}
.submit-btn:hover {
  cursor: pointer;
  text-decoration: underline;
}
