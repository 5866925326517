@import '~antd/dist/antd.css';
/* NOTE: FOR CHANGE ANTD STYLES */
.ant-input {
  border-radius: 6px !important;
  /* border-color: #fff !important; */
}

.main-table.ant-table-wrapper {
  width: 100%;
}

.page-filters.ant-form-inline .ant-form-item:last-child {
  margin-right: 0;
}

.page-filters.ant-form-inline .filters .ant-form-item {
  min-width: 220px;
  padding: 1em 0;
}

.page-filters.ant-form-inline .controls .ant-row {
  height: 100%;
}
